@function random_range($min, $max) {
  $rand: random();
  $random_range: $min + floor($rand * (($max - $min) + 1));
  @return $random_range;
}

.star {
  --primary-color: #0ff;
  --star-color: var(--primary-color);
  --star-tail-length: 6em;
  --star-tail-height: 2px;
  --star-width: calc(var(--star-tail-length) / 6);
  --fall-duration: 9s;
  --tail-fade-duration: var(--fall-duration);

  position: absolute;
  top: -20vh;
  width: var(--star-tail-height);
  height: var(--star-tail-length);
  color: var(--star-color);
  background: linear-gradient(45deg, currentColor, transparent);
  border-radius: 50%;
  filter: drop-shadow(0 0 6px currentColor);
  animation: fall var(--fall-duration) var(--fall-delay) linear infinite,
    tail-fade var(--fall-duration) var(--fall-delay) ease-out infinite;

  &::before,
  &::after {
    position: absolute;
    content: "";
    // top: calc(var(--star-tail-length) - 5px);
    left: 0;
    width: 100%;
    height: var(--star-width);
    background: linear-gradient(45deg, transparent, currentColor, transparent);
    border-radius: inherit;
    animation: slowDown var(--fall-duration) var(--fall-delay) ease-out infinite;
    // blink var(--fall-duration) var(--fall-delay) linear infinite;
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }
}

@keyframes fall {
  to {
    top: calc(100vw + 100vh);
  }
}

@keyframes tail-fade {
  0%,
  50% {
    height: var(--star-tail-length);
    opacity: 1;
  }

  70%,
  80% {
    height: 0;
    opacity: 0.4;
  }
}

@keyframes slowDown {
  0%,
  50% {
    top: calc(var(--star-tail-length) * 0.9);
  }

  70%,
  80% {
    top: -10px;
  }
}
