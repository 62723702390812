.App {
  text-align: center;
}

.App-logo {
  height: 400px;
  pointer-events: none;
  filter: drop-shadow(10px 10px 200px #ff0) drop-shadow(-10px -10px 30px #f0f);
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@media (max-width: 600px) {
  .App-logo {
    height: 300px;
  }
  .sun svg circle {
    r: 150 !important;
  }
}

.bodySection {
  /* background-color: hsl(210, 50%, 14%); */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: #61dafb;
}

#line38 {
  transform: translateY(10px);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
